<template>
	<Modal
		class="modal"
		size="m"
		v-model="localModal"
	>
		<div class="modal-content">
			<div class="modal-content-texts">
				<h3>{{ modalProps.title }}</h3>
				<RichText
					size="m"
					:text="modalProps.text"
				/>
			</div>
			<div class="modal-content-buttons">
				<TnButton
					:size="isMobile ? 's' : 'm'"
					:loading="loading"
					:disabled="error"
					:text="dynamicContent(modalProps.confirmButtonText)"
					@click="confirmPrompt"
				/>
				<TnButton
					:size="isMobile ? 's' : 'm'"
					secondary
					:text="dynamicContent(modalProps.cancelButtonText)"
					@click="closeModal"
					@keydown.escape="closeModal"
				/>
			</div>
		</div>
	</Modal>
</template>
<script>
export default defineNuxtComponent({
	name: "ECommerceModal",

	props: {
		modalContent: {
			type: Object,
			default: () => ({}),
		},
		modal: {
			type: Boolean,
			default: false,
		},
		loading: {
			type: Boolean,
			default: false,
		},
		error: {
			type: Boolean,
			default: false,
		},
	},

	data() {
		return {
			localModal: this.modal,
		};
	},

	computed: {
		modalProps() {
			return {
				title: this.modalContent?.title || this.defaultModalContent.title,
				text: this.modalContent?.text || this.defaultModalContent.text,
				confirmButtonText: this.modalContent?.confirmButtonText || this.defaultModalContent.confirmButtonText,
				cancelButtonText: this.modalContent?.cancelButtonText || this.defaultModalContent.cancelButtonText,
			};
		},
		defaultModalContent() {
			return {
				title: "Er du sikker?",
				text: "Er du helt sikker på at du vil fjerne produktet fra handlekurven?",
				confirmButtonText: "Ja, fjern",
				cancelButtonText: "Avbryt",
			};
		},
		dynamicContent() {
			return this.$dynamicContent({
				newDeviceName: this.modalContent?.deviceNames?.newDevice,
				oldDeviceName: this.modalContent?.deviceNames?.currentDevice,
			});
		},
		isMobile() {
			const mobileBreakpoint = 768;
			return window?.innerWidth < mobileBreakpoint;
		},
	},

	methods: {
		confirmPrompt() {
			this.$emit("confirmPrompt");
		},
		closeModal() {
			this.$emit("closeModal");
		},
	},

	watch: {
		localModal(newVal) {
			this.$emit("update:modelValue", newVal);
		},
		modal: {
			handler(newVal) {
				this.localModal = newVal;
			},
			immediate: true,
		},
	},
});
</script>
<style lang="scss" scoped>
:deep(.modal-wrapper) {
	width: 100vw;
	height: 100vh;
	transform: translateY(0) !important;
}

.modal-content {
	display: flex;
	flex-direction: column;
	gap: $spacing-xl;

	@include font-text-l;

	h3 {
		margin-bottom: $spacing-xs;

		@include font-title-m;
	}

	&-texts {
		display: flex;
		flex-direction: column;
		gap: $spacing-xs;
	}

	&-buttons {
		display: flex;
		flex-direction: row;
		align-self: flex-start;
		gap: $spacing-m;

		@include breakpoint(mobile) {
			flex-direction: column;
		}
	}
}
</style>
